<template>
  <div class="user">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "User"
};
</script>
<style lang="scss" scoped>
.user {
  width: 100%;
}
</style>
